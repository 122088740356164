import * as Sentry from '@sentry/remix'
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/docs/en/main/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'
import posthog from 'posthog-js'

if (window.env.SENTRY_ENVIRONMENT! !== 'development') {
  Sentry.init({
    dsn: 'https://1ffcfc8150ffb163e99ac1a8a80bf5e0@o578467.ingest.us.sentry.io/4507431647903744',
    tracesSampleRate: 1,
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
    environment: window.env.SENTRY_ENVIRONMENT || 'development',
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
      }),
    ],
  })
}

function hydrate() {
  posthog.init('phc_jhV1osGiXYd3MSyC90B6znX5ydEQhLacwJhI2x8rm8Z', {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only',
  })

  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <RemixBrowser />
      </StrictMode>
    )
  })
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate)
} else {
  window.setTimeout(hydrate, 1)
}
